/* eslint-disable no-return-assign */

import { HOST_WEBSITE } from '../config-global';

export function copyToClipboard(str) {
  navigator.clipboard.writeText(str);
}

export function removeNulls(object) {
  return Object.entries(object).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {});
}

export function getTotalValue(dataFiltered) {
  let totalValue = 0;
  let currency = null;
  let hasMultipleCurrencies = false;

  dataFiltered.forEach(({ totalValue: itemTotalValue, currency: itemCurrency }) => {
    totalValue += itemTotalValue;
    if (currency && itemCurrency && currency !== itemCurrency) {
      hasMultipleCurrencies = true;
    }
    currency = itemCurrency;
  });

  return { value: totalValue, currency: !hasMultipleCurrencies && currency };
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export function parseIdFromString(string) {
  const matches = string.match(/^(\[[#]?(.+)\])(.*)/);
  if (matches && matches.length > 3) {
    return { id: matches[2], text: matches[3] };
  }

  return { id: null, text: string };
}

let navFetched = false;

export function getMergedNavConfig(currentNavConfig, navigation) {
  if (navFetched || !navigation || !currentNavConfig?.[1]?.children?.[0]?.items) {
    return currentNavConfig;
  }
  navFetched = true;
  navigation.forEach((navigationItem) => {
    currentNavConfig[1].children[0].items.push({
      title: navigationItem.primary.title,
      path: `${HOST_WEBSITE}${navigationItem.primary.link.url}`,
    });
  });
  return currentNavConfig;
}
